import HomeIcon from "../assets/home/home.svg";
import SaleIcon from "../assets/home/aboutUs.svg";
import RentIcon from "../assets/home/gavel.svg";
import ContactUsIcon from "../assets/home/support.svg";

export const NAVIGATION_ITEMS = [
    { name: 'Home', path: '/' },
    { name: 'Courses', path: '/courses' }
];

export const NAVIGATION_ITEMS_DRAWER= [
    { name: 'Home', path: '/' , icon:HomeIcon},
    { name: 'Courses', path: '/courses' ,icon:SaleIcon}
];
