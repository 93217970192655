import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";

export default function DevPath(props) {
  return (
    <div key={props.key} className="cardSlideContainer ">
      <Card className="cardContainer">
        <img src={props.imgsrc} alt="tower" className="cardImg" />
        <CardContent className="cardContentContainer">
          <Grid
            container
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography variant="body1" className="cardDescr">
                {props.descr}
              </Typography>
            </Grid>

            <Grid item style={{ width: "75%" }}>
              <Typography variant="body1" style={{marginLeft: "-1rem"}}>
                What you will learn?
              </Typography>
              <Typography
                variant="body1"
                className="cardLoc"
                style={{ textAlign: "left" }}
              >
                <ul style={{ lineHeight: "2rem" }}>
                  {props.loc.map((item) => (
                    <li>{item}</li>
                  ))}
                </ul>
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
