import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./components_bharath.scss";
import Topimg from "../../../assets/home/marketing.png";
import Tick from "../../../assets/home/tick.png";

export default function FeaturedList() {
  return (
    <div className="featuredListContainer">
      {/* container for left n right containers */}
      <Grid
        container
        className="featuredListBox"
        direction={{ md: "row", xs: "column" }}
        justifyContent={{ md: "space-between", xs: "flex-start" }}
        alignItems={{ md: "space-between", xs: "center" }}
        style={{display: "flex"}}
      >
        {/* left container */}
       

        {/* right container */}
        <Grid
          item
          className="rightContainer"
          container
          direction={{ md: "column", xs: "column" }}
          rowSpacing={{ md: 2, xs: 4 }}
          md={5}
          justifyContent={{ md: "flex-start", xs: "center" }}
          alignItems={{ md: "flex-start", xs: "center" }}
        >
          <Grid item>
            <Typography variant="h4" className="rightContainerTitle">
              We also provide social media marketing as a service
            </Typography>
          </Grid>

          <Grid item style={{marginTop: "2rem"}}>
            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Boost Online Presence
            </Typography>
            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
             Content creation
            </Typography>
            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              SEO and SEM
            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Targeted Strategies
            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Platform Focus


            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Data-driven Insights
            </Typography>
            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Maximized Reach
            </Typography> <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Brand Visibility
            </Typography> <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Tailored Solutions
            </Typography>
          </Grid>
        </Grid>
        <Grid item className="leftContainer" md={5}>
          <img className="topImg" src={Topimg} alt="img" style={{height: '70vh'}}/>
          {/* <img className='bottomImg' src={BottomImg} alt='img'/> */}
        </Grid>
      </Grid>
    </div>
  );
}
