import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./components_bharath.scss";
import Topimg from "../../../assets/home/whyLearn.png";
import Tick from "../../../assets/home/tick.png";

export default function FeaturedList() {
  return (
    <div className="featuredListContainer">
      {/* container for left n right containers */}
      <Grid
        container
        className="featuredListBox"
        direction={{ md: "row", xs: "column" }}
        justifyContent={{ md: "space-between", xs: "flex-start" }}
        alignItems={{ md: "space-between", xs: "center" }}
        style={{display: "flex"}}
      >
        {/* left container */}
        <Grid item className="leftContainer" md={5}>
          <img className="topImg" src={Topimg} alt="img" />
          {/* <img className='bottomImg' src={BottomImg} alt='img'/> */}
        </Grid>

        {/* right container */}
        <Grid
          item
          className="rightContainer"
          container
          direction={{ md: "column", xs: "column" }}
          rowSpacing={{ md: 2, xs: 4 }}
          md={5}
          justifyContent={{ md: "flex-start", xs: "center" }}
          alignItems={{ md: "flex-start", xs: "center" }}
        >
          <Grid item>
            <Typography variant="h4" className="rightContainerTitle">
              Why learn with Dev.TechYatra ?
            </Typography>
          </Grid>

          <Grid item style={{marginTop: "2rem"}}>
            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Trusted by 1000s of developers and 100s of companies
            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Have 98% completion rate and we take pride in supporting every
              student who enrolls in our live classes
            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              We make learning fun and affordable for everyone. Beginners to
              expert level
            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              We provide 1:1 classes with our teaching assistants
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
