import React from 'react';
import './CardComponent.scss';
import GrowImage from "../../../assets/home/grow.png"; 


const CardComponent = (props) => {
  return (
    <div className="card" style={{ backgroundImage: `url(${props.imgsrc})`, backgroundRepeat: 'round' }}>
      <div className="card-gradient" />
      <div className="card-title">
        <p style={{marginBottom: '0.6rem'}}>{props.descr}</p>
        <span style={{fontSize: '0.8rem'}}>
        <p className='service-chip'>{props.loc[0]}</p>
        <p className='service-chip'>{props.loc[1]}</p>
        <p className='service-chip'>{props.loc[2]}</p>
        </span>
      </div>
      <div className="card-hover-content">
        <p>{props.cardDesc}</p>
      </div>
    </div>
  );
}

export default CardComponent;
