import React from "react";
import { Grid } from "@mui/material";
import "./components.scss";
import Brands from "./brands";

function Member() {
  const windowWidth = window.innerWidth;

  return (
    <div className="howitworksContainer" style={{width: "100%", background: "#000000"}}>
      <Grid container>
        <Grid
          container
          className="howitworksText"
          alignItems="center"
          justifyContent="center"
        >
          <div
            style={{
              height: windowWidth <= 600 ? "6rem" : "8rem",
              fontFamily: "Manrope",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "2rem",
              lineHeight: "55px",
              color: "#FFFFFF",
              textAlign: "center",
              marginBottom: 50,
              marginTop: 30
            }}
          >
            Educating industry level engineers around the Globe
          </div>
        </Grid>
        <Grid
          container
          className="howitworksCards"
          spacing={windowWidth < 600 ? 4 : 10}
          style={{
            margin: "0.5rem",
            paddingTop: 0,
            height: windowWidth < 600 ? "4rem" : "6rem",
            marginTop: windowWidth<600 && "8rem"
          }}
        >
          <Brands />
        </Grid>
      </Grid>
    </div>
  );
}
export default Member;
