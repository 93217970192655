import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./pages/homePage/index";
import HomeLearning from "./pages/learningPath/index";

function App() {
	return (
		<BrowserRouter>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/courses" element={<HomeLearning />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
