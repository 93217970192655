import React, { useState } from "react";
import "./brands.scss";
import "@fontsource/roboto";

function Brands(props) {
  return (
    <div class="slider">
      <div class="slide-track">
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Delloite</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Delloite</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Wallmart</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Ellucian</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Global Logic</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Delloite</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Delloite</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Wallmart</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Ellucian</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Global Logic</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Delloite</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Delloite</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Wallmart</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Ellucian</p>
          </span>
        </div>
        <div class="slide">
          <span
            className="slide-text"
            height="100"
            width="250"
            style={{
              display: "flex",
              height: 70,
              width: 200,
              background: "#FFFFFF",
              borderRadius: 12,
            }}
          >
            <p>Global Logic</p>
          </span>
        </div>
      </div>
    </div>
  );
}
export default Brands;
