import React from "react";
import "./homePage.scss";
import HowItWorks from "./components/howitworks";
import FeaturedList from "../homePage/components/FeaturedList";
import NavHomeSearch from "../homePage/components/NavHomeSearch";
import TopOffers from "../homePage/components/TopOffers";
import AboutUs from "../homePage/components/AboutUs";
import Footer from "../../Components/Footer";
import HomeBg from "../../assets/home/walogo.png";
import Member from "../homePage/components/Member"
import TechClients from "./components/TechClients";


function HomePage() {
  return (
    <>
        <div className="homepage">
          <NavHomeSearch />
          <AboutUs />
          <HowItWorks />
          
          <FeaturedList />
          <TopOffers />
          <TechClients />
          {/* <Member /> */}
          <Footer />
          <div
            className="waicon"
            style={{
              width: "4rem",
              height: "4rem",
              position: "fixed",
              bottom: 32,
              right: 58,
            }}
          >
            <a aria-label="Chat on WhatsApp" href="https://wa.me/918867554727">
              <img
                src={HomeBg}
                style={{
                  width: "10rem",
                  position: "fixed",
                  bottom: 10,
                  right: 10,
                }}
              />
            </a>
          </div>
        </div>
    </>
  );
}
export default HomePage;
