import { Grid, Typography } from "@mui/material";
import React from "react";
import "./components_bharath.scss";
import HomeBg from "../../../assets/home/home.png";

export default function NavHomeSearch() {
  const windowWidth = window.innerWidth;

  return (
    <div style={{ height: "90vh", marginTop: "5vh" }}>
    <img src={HomeBg} style={{width:windowWidth, height: "90vh"}} className='homeBg' alt='backgroundimage'/>
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        className="navHomeContainer"
      >
        {/* home section body */}
        <Grid item className="navHomeBodyContainer" style={{top: "10vh"}}>
                <Typography variant="h1" className="bodyTitle">
                  <span className="sq_header">
                    <h2>
                      Launch your future career by learning at 
                      <span style={{color: "#ff9a00"}}> DEV.TECHYATRA</span>
                    </h2>
                  </span>
                </Typography>
                <Typography className="bodyDescr" variant="body1">
                  Learn technical skills from experts. Live tech boot capms and online classes to kickstart or accelerate your career
                </Typography>
              </Grid>
        {/* buy and search*/}
      </Grid>
    </div>
  );
}
