import React from "react";
import "./TechClients.scss";
import ClientCard from "../components/cards/ClientCards";
import clientImage1 from "../../../assets/home/equitek.png"; // Replace with actual image path
import clientImage2 from "../../../assets/home/volvo.png";
import clientImage3 from "../../../assets/home/ourprint.png";
import clientImage4 from "../../../assets/home/yatracoffee.png";
import clientImage5 from "../../../assets/home/etram.png";
import clientImage6 from "../../../assets/home/hpasa.png";


const clients = [
  { name: "Equitek", image: clientImage1, link: "https://apps.apple.com/in/app/myshifthealth/id6443498337", work: 'Website | App | Complete Architecture' },
  { name: "Volvo", image: clientImage2, link: "https://www.volvobuses.com/in/", work: '2D animation' },
  {
    name: "Our Print",
    image: clientImage3,
    link: "https://ourprint.in/",
    work: 'App'
  },
  { name: "Yatra Coffee", image: clientImage4, link: "https://www.instagram.com/yatracoffee/", work: 'Website | Branding' },
  { name: "Etram dance academy", image: clientImage5, link: "https://www.instagram.com/etramdanceacademy/", work: 'Website' },
  { name: "HP and Associates", image: clientImage6, link: "https://hpasa.com/", work: 'Website | Brand theme' },
];

const TechClients = () => {
  return (
    <span className="howitworksContainer" style={{ marginTop: "15vh" }}>
      <div
        style={{
          width: "auto",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "2rem",
          color: "#000000",
          textAlign: "left",
          marginLeft: "1rem",
        }}
      >
        Our Happy Clients
      </div>

      <div className="client-cards-container">
        {clients.map((client, index) => (
          <ClientCard
            key={index}
            clientName={client.name}
            clientImage={client.image}
            clientLink={client.link}
            clientWork={client.work}
          />
        ))}
      </div>
    </span>
  );
};

export default TechClients;
