import React from "react";
import "./footer.scss";
import { Grid, Box } from "@mui/material";
import "./footer.scss";
import Logo from "../assets/home/logo.png";
import LinkedIn from "../assets/home/LinkedIn.svg";
import Instagram from "../assets/home/Instagram.svg";

const screenWidth = window.outerWidth;

function Footer() {
  return (
    <Grid
      id="footerContainer"
      container
      direction={{ md: "row", xs: "column" }}
      align-items={{ md: "center", xs: "center" }}
      rowSpacing={{ md: 4, xs: 8 }}
      justifyContent={{ md: "space-evenly", xs: "space-evenly" }}
      style={{ background: "#000000", width: "100vw", overflow: "clip" }}
    >
      <Grid
        item
        container
        direction={{ md: "column", xs: "column" }}
        className="firstBox"
        md={6}
        xs={12}
        style={{
          marginBottom: screenWidth <= 960 ? "8rem" : "4rem",
          marginLeft: screenWidth <= 960 && "1rem",
        }}
      >
        <Grid item className="firstBoxItem1" style={{ marginTop: "2rem" }}>
          <Box className="navbar-logo">
            <img
              src={Logo}
              alt="logo"
              className={`navbarLogo1 ${
                screenWidth <= 960 ? "navbarLogo1-small" : ""
              }`}
              style={{ marginLeft: "1rem" }}
            />
            <span className="sq_header">
              <h3>
                <span style={{ color: "#FFFFFF", marginLeft: "1rem" }}>
                  DEV.TECHYATRA
                </span>
              </h3>
            </span>
          </Box>
        </Grid>

        <Grid item className="firstBoxItem3"></Grid>
        <Grid item className="firstBoxItem4">
          <span
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1rem",
            }}
          >
            <p style={{ color: "#D1B06B" }}>Follow: </p>
            <a
              href="https://www.linkedin.com/company/techyatra-business-solutions-llp/"
              target="_blank"
              style={{marginLeft: '1rem'}}
            >
              <img
                className="socialIcons"
                src={LinkedIn}
                style={{ color: "#FFFFFF" }}
                alt="ln"
              />
            </a>

            <a
              href="https://www.instagram.com/dev.techyatra/"
              target="_blank"
              style={{marginLeft: '1rem'}}
            >
              <img className="socialIcons" src={Instagram} alt="Ig" />
            </a>

          </span>
        </Grid>
      </Grid>

      <Grid md={6} xs={0}></Grid>
    </Grid>
  );
}

export default Footer;
