import React, { useState } from "react";
import { Grid } from "@mui/material";
import "./components.scss";
import "./components_bharath.scss";
import DevPath from "./cards/DevPath";
import Estate1 from "../../../assets/home/learn.png";
import Estate2 from "../../../assets/home/build.png";
import Estate3 from "../../../assets/home/grow.png";

const card_details_array = [
  {
    id: 1,
    img: Estate1,
    loc: ["HTML", "CSS", "SCSS", "JavaScript", "ReactJs"],
    descr: "Frontend",
  },
  {
    id: 2,
    img: Estate2,
    loc: [
      "JavaScript",
      "Data Structure & Algorithm",
      "NodeJs",
      "MySQL",
      "Design Principles",
    ],
    descr: "Backend",
  },
  {
    id: 3,
    img: Estate3,
    loc: ["Frontend", "Backend", "Integration", "Testing", "Internship"],
    descr: "Full Stack",
  },
];
function HowItWorks(props) {
  return (
    <div className="howitworksContainer" style={{ marginTop: "20vh" }}>
      <Grid container>
        <Grid
          container
          className="howitworksText devPaths"
          alignItems="center"
          justifyContent="left"
        >
          <div
            style={{
              width: "auto",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "2rem",
              color: "#000000",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
            Development paths
          </div>
        </Grid>
        <Grid container className="howitworksCards" spacing={10}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="howItWorksElement"
          >
            <DevPath
              id={card_details_array[0].id}
              imgsrc={card_details_array[0].img}
              loc={card_details_array[0].loc}
              descr={card_details_array[0].descr}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="howItWorksElement"
          >
            <DevPath
              id={card_details_array[1].id}
              imgsrc={card_details_array[1].img}
              loc={card_details_array[1].loc}
              descr={card_details_array[1].descr}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="howItWorksElement"
          >
            <DevPath
              id={card_details_array[2].id}
              imgsrc={card_details_array[2].img}
              loc={card_details_array[2].loc}
              descr={card_details_array[2].descr}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default HowItWorks;
