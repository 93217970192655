import React from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";
const screenWidth = window.outerWidth;

export default function HowItWorksCard(props) {
  return (
    <div key={props.key} className="cardSlideContainer ">
      <Card className="cardContainer">
        <img src={props.imgsrc} alt="tower" className="cardImg" />
        <CardContent className="cardContentContainer">
          <Grid
            container
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <Typography
                variant="body1"
                className="cardDescr"
                style={{
                  fontSize: screenWidth <= 960 ? "1rem" : "1.2rem",
                }}
              >
                {props.descr}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                className="cardLoc"
                style={{ fontSize: "0.9rem" }}
              >
                {props.loc}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
