import { Grid, Typography } from "@mui/material";
import React from "react";
import { useRef } from "react";
import "./components_bharath.scss";

import Estate1 from "../../../assets/home/learn.png";
import Estate2 from "../../../assets/home/build.png";
import Estate3 from "../../../assets/home/grow.png";
import HowItWorksCard from "./cards/howitWorksCardDev";
const card_details_array = [
  {
    id: 1,
    img: Estate1,
    loc: "Learn from curriculum from best curated online classes taught by industry level experts. Choose from the multiple offering that suits your career path.",
    descr: "Learn",
  },
  {
    id: 2,
    img: Estate2,
    loc: "Build multiple projects along the way, from simple websites to full fledge websites. We will also help you build your complete portfolio.",
    descr: "Build",
  },
  {
    id: 3,
    img: Estate3,
    loc: "We will help you apply to different companies and guide you through your career path, be it promotions or applying into new companies",
    descr: "Grow",
  },
];

export default function TopOffers() {
  const myref = useRef(null);
  const progref = useRef(null);

  const buttonScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  const getScrollPercent = () => {
    return `${
      (myref.current.scrollLeft /
        (myref.current.scrollWidth - myref.current.clientWidth)) *
      100
    }`;
  };
  const scrollProgressFunc = () => {
    progref.current.style.width = `${getScrollPercent()}%`;
  };

  return (
    <div>
      <Grid
        container
        direction="column"
        id="topOffersContainer"
        justifyContent="center"
      >
        {/* heading */}
        <Grid item className="topOffersItem1">
          <Typography variant="h2" className="topOffersTitle" style={{fontWeight: 600}}>
            How it works
          </Typography>
        </Grid>

        {/* description and button */}
        {/* <Grid item container direction={{md:'row',xs:'column'}} justifyContent={{md:'space-between',xs:'space-between'}} alignItems={{md:'center',xs:'flex-start'}} className='topOffersItem2' > */}
        {/* <Grid item md={8}>
                <Typography variant='body1' className='topOffersDescr'>
                    Fulfill your career dreams, enjoy all the achievements of the city center and luxury housing to the fullest.
                </Typography>
            </Grid> */}
        {/* <Grid item md={3}>
                <Button variant='outlined' className='topOffersButton'>Show All Offers</Button>
            </Grid> */}
        {/* </Grid> */}

        {/* progress bar and left right buttons */}
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          className="topOffersItem3"
        >
          <Grid item className="progressBarContainer">
            <div className="progressBar" ref={progref}></div>
          </Grid>
          <Grid
            item
            container
            className="leftRightBtnContainer"
            spacing={2}
            md={2}
          >
            {/* <Grid item>
             <img alt='left' className='leftBtn' src={Left_arrow}
              onClick={()=>{buttonScroll(myref.current, 25, 100, -10);}} />     
          </Grid>
          <Grid item> 
           <img alt='right' className='rightBtn'  src={Right_arrow}
           onClick={()=>{buttonScroll(myref.current, 25, 100, 10);}}/>
          </Grid>                       */}
          </Grid>
        </Grid>

        {/* card carsousel */}
        <Grid item className="topOffersItem4">
          <div
            onScroll={scrollProgressFunc}
            className="manyCardsContainer scroll-div"
            ref={myref}
          >
            {card_details_array.map((obj) => (
              <HowItWorksCard
                key={obj.id}
                cost={obj.cost}
                loc={obj.loc}
                descr={obj.descr}
                imgsrc={obj.img}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
