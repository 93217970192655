import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import "./components_bharath.scss";
import Topimg from "../../../assets/home/whyLearn.png";
import Tick from "../../../assets/home/tick.png";

export default function FeaturedList() {
  return (
    <div className="featuredListContainer">
      {/* container for left n right containers */}
      <Grid
        container
        className="featuredListBox"
        direction={{ md: "row", xs: "column" }}
        justifyContent={{ md: "space-between", xs: "flex-start" }}
        alignItems={{ md: "space-between", xs: "center" }}
        style={{ display: "flex" }}
      >
        {/* left container */}
        <Grid item className="leftContainer" md={5}>
          <img className="topImg" src={Topimg} alt="img" />
          {/* <img className='bottomImg' src={BottomImg} alt='img'/> */}
        </Grid>

        {/* right container */}
        <Grid
          item
          className="rightContainer"
          container
          direction={{ md: "column", xs: "column" }}
          rowSpacing={{ md: 2, xs: 4 }}
          md={5}
          justifyContent={{ md: "flex-start", xs: "center" }}
          alignItems={{ md: "flex-start", xs: "center" }}
        >
          <Grid item>
            <Typography variant="h4" className="rightContainerTitle">
              About Us
            </Typography>
          </Grid>

          <Grid item style={{ marginTop: "2rem" }}>
            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Trusted by 100s of clients from companies across the GLobe
            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Have 100% completion rate and we take pride in supporting every
              business who partner with us
            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              At TechYatra, we specialize in elevating your business's online
              presence and ensuring visibility in the dynamic digital landscape.
            </Typography>

            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Our mission is to seamlessly transition your company and products
              into the digital realm, propelling your business to new heights.
              Renowned for our unwavering support, we cater to individuals and
              businesses of all scales, employing strategic approaches to
              enhance visibility and marketability efficiently.
            </Typography>
            <Typography
              variant="body1"
              className="rightContainerDescr whyLearnDesc"
            >
              <img
                style={{ width: "1rem", height: "1rem", marginRight: "1rem" }}
                src={Tick}
                alt="img"
              />
              Let us be your partner in navigating the digital journey and
              achieving unparalleled success.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
