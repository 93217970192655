import React, { useState } from "react";
import { Grid } from "@mui/material";
import "./components.scss";
import "./components_bharath.scss";
import DevPath from "./cards/DevPath";
import Estate1 from "../../../assets/home/branding.png";
import Estate2 from "../../../assets/home/app-dev.png";
import Estate3 from "../../../assets/home/animation.png";
import ServiceCard from "../components/ServicesCard";

const card_details_array = [
  {
    id: 1,
    img: Estate1,
    loc: ["UI/UX Designing", "Branding", "Logo Designing"],
    descr: "Design & Branding",
    cardDesc: "Elevate your brand with our expert UI/UX Designing for user-friendly experiences. Our Branding and Logo Designing create impactful visuals that connect with your audience."
  },
  {
    id: 2,
    img: Estate2,
    loc: [
      "Website Development",
      "Mobile App Development",
      "Native Apps"
    ],
    descr: "Development Solutions",
    cardDesc: "Turn ideas into reality with our Website Development and Mobile App Development services. Our Native Apps ensure seamless performance tailored to your users."
  },
  {
    id: 3,
    img: Estate3,
    loc: ["2D & 3D Animation", "E-commerce Website", "SEO & Social Media"],
    descr: "Animation & Marketing",
    cardDesc: "Engage your audience with our 2D & 3D Animation services. Combined with our E-commerce Website solutions and SEO & Social Media expertise, we help your brand shine online."
  },
];
function HowItWorks(props) {
  return (
    <div className="howitworksContainer" style={{ marginTop: "20vh" }}>
      <Grid container>
        <Grid
          container
          className="howitworksText devPaths"
          alignItems="center"
          justifyContent="left"
        >
          <div
            style={{
              width: "auto",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "2rem",
              color: "#000000",
              textAlign: "left",
              marginLeft: "1rem",
            }}
          >
           Our Services
          </div>
        </Grid>
        <Grid container className="howitworksCards" spacing={10}>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="howItWorksElement"
          >
            <ServiceCard
              id={card_details_array[0].id}
              imgsrc={card_details_array[0].img}
              loc={card_details_array[0].loc}
              descr={card_details_array[0].descr}
              cardDesc={card_details_array[0].cardDesc}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="howItWorksElement"
          >
            <ServiceCard
              id={card_details_array[1].id}
              imgsrc={card_details_array[1].img}
              loc={card_details_array[1].loc}
              descr={card_details_array[1].descr}
              cardDesc={card_details_array[1].cardDesc}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            className="howItWorksElement"
          >
            <ServiceCard
              id={card_details_array[2].id}
              imgsrc={card_details_array[2].img}
              loc={card_details_array[2].loc}
              descr={card_details_array[2].descr}
              cardDesc={card_details_array[2].cardDesc}
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
export default HowItWorks;
