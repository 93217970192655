import React from 'react';
import './ClientCard.scss';

const ClientCard = ({ clientImage, clientName, clientLink, clientWork }) => {
  return (
    <div className="client-card">
      <div className="image-container">
        <img src={clientImage} alt={clientName} className="client-image" />
      </div>
      <div className="card-content">
        <h4>{clientName}</h4>
        <p style={{marginBottom: '10px'}}>{clientWork}</p>
        <a href={clientLink} className="visit-link" target="_blank" rel="noopener noreferrer">
          See work
        </a>
      </div>
    </div>
  );
};

export default ClientCard;
